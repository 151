<script setup>
import Footer from '@/components/FooterComponent.vue'
import { onMounted } from 'vue'

onMounted(() => {
  // load job board on mount
  Grnhse.Iframe.load()
})
</script>

<template>
  <q-page class="-bg-yellow-50">
    <div style="padding-top: 80px; min-height: 90vh">
      <div class="-mx-1 md:-mx-10 md:-grid md:-grid-flow-col md:-grid-cols-2">
        <div class="-max-h-screen -content-center">
          <q-card flat class="-bg-transparent -text-black md:-mx-5">
            <q-card-section class="-pt-7 md:-pt-10">
              <div class="md:text-left -text-center -text-6xl -font-bold md:-text-left lg:-text-7xl">Join DL Trading</div>
              <div class="-mt-10 -text-center md:-text-left">
                <div class="-max-w-screen-sm -text-lg">
                  Roles in USA and Europe. We are always on the lookout for curious and creative minds. Please apply to any of our
                  active roles below, or reach out directly hr@dltrading.io
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="-content-top md:-content-center" style="min-height: 90vh">
          <div id="grnhse_app" class="-text-black"></div>
        </div>
      </div>
    </div>
    <Footer />
  </q-page>
</template>

<style lang="scss" scoped>
.bg-image {
  background-image: url('../assets/orange-out.png');
  background-repeat: cover;
  background-size: 140vw 140vh;
  background-color: black;
}
</style>
