<script setup lang="ts">
import { ref } from 'vue'
import { RouterLink } from 'vue-router'

const leftDrawerOpen = ref(false)

function toggleLeftDrawer() {
  leftDrawerOpen.value = !leftDrawerOpen.value
}
</script>

<template>
  <q-layout view="hHh Lpr lFf" class="-bg-black">
    <q-header class="-bg-black -bg-opacity-100 -backdrop-blur-sm">
      <q-toolbar style="height: 80px">
        <div class="-flex -w-full -flex-wrap -px-6">
          <q-toolbar-title>
            <div
              class="dl-font .dl-font -flex -flex-row -py-1 -align-bottom -font-bold"
              style="font-size: 1.875rem !important; line-height: 2.25rem"
            >
              <router-link to="/"
                ><img src="../assets/dlLogo.png" style="margin-top: 1px; height: 40px; width: auto"
              /></router-link>
            </div>
          </q-toolbar-title>
          <div class="-flex -flex-wrap">
            <q-tabs shrink no-caps indicator-color="transparent" class="gt-xs">
              <router-link to="/"><q-tab name="home" label="Home" rounded /></router-link>
              <router-link to="/careers"> <q-tab name="careers" label="Careers" rounded /></router-link>
              <a href="mailto:HR@dltrading.io" target="_blank" class="-underline"
                ><q-btn label="Contact" flat class="dl-yellow-bg -ml-3 -text-white"
              /></a>
            </q-tabs>

            <q-btn flat dense round icon="menu" aria-label="Menu" @click="toggleLeftDrawer" class="lt-sm" />
          </div>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" side="right" class="-bg-yellow-50">
      <q-list class="-pl-5 -pt-5" separator>
        <q-item class="-flex -items-center -justify-end" active-class="-text-orange-500">
          <div class="-self-center -font-bold">DL Trading</div>
          <q-space /><q-btn dense round unelevated icon="mdi-close" @click="toggleLeftDrawer" color="black" size="md" />
        </q-item>

        <q-item to="/" exact clickable class="-mt-10 -text-black">
          <q-item-section>Home</q-item-section>
        </q-item>

        <q-item to="/careers" exact clickable class="-text-black">
          <q-item-section>Careers</q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container class="-pt-0">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<style lang="scss"></style>
