<script setup lang="ts">
import { useWindowSize, watchDebounced } from '@vueuse/core'
import { onMounted, ref } from 'vue'

// paints gradient above footer
const { width, height } = useWindowSize()

const footerGradientContainer: any = ref(null)
const footerGradientCanvas: any = ref(null)

const paintCanvas = () => {
  const canvas = footerGradientCanvas.value
  const ctx = canvas.getContext('2d')
  const rect = footerGradientContainer.value.getBoundingClientRect()
  canvas.width = rect.width
  canvas.height = rect.height

  let gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height)
  gradient.addColorStop(1, 'rgba(238,166,95, 1)')
  gradient.addColorStop(0.6, 'rgba(243, 166, 12, 1)')
  gradient.addColorStop(0, 'rgba(0, 0, 0, 1)') // Black

  ctx.fillStyle = gradient
  ctx.fillRect(0, 0, canvas.width, canvas.height)
}

onMounted(() => {
  paintCanvas()
})

watchDebounced(
  [width, height],
  () => {
    paintCanvas()
  },
  { debounce: 10, maxWait: 300 }
)
</script>

<template>
  <div ref="footerGradientContainer">
    <canvas ref="footerGradientCanvas"></canvas>
  </div>
  <div class="-flex -bg-yellow-50">
    <div class="-ml-auto -mr-auto -grow-0 -content-center -self-start">
      <div class="-grid -grid-flow-col -grid-cols-1">
        <div class="-content-top -py-10 -text-center">
          <p class="-mb-1 -text-lg -font-semibold">Dime Line Trading</p>
          <p class="-mb-3">Quantitative sports trading and odds.</p>

          <p class="-mb-3">
            <!-- <span class="-select-none">Email Us: </span
          > -->
            <a class="-cursor-pointer -select-text -underline" href="mailto:HR@dltrading.io">HR@dltrading.io</a>
          </p>
          <p>
            <span class="-select-none">Join Our Team: </span
            ><router-link class="-cursor-pointer -select-text -underline" to="/careers">Careers</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="-bg-yellow-50 -py-2 -text-center">
    <p class="-text-center -text-xs -font-light">© Dime Line Trading {{ new Date().getFullYear() }}</p>
  </div>
</template>

<style lang="scss" scoped>
canvas {
  height: 10px;
  width: auto;
}
</style>
