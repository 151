import { createApp } from 'vue'

import '@dlStyle'
import '@/assets/custom.scss'

import App from './App.vue'
import router from './router'

import { Quasar } from 'quasar'
import iconSet from 'quasar/icon-set/mdi-v7'
import '@quasar/extras/mdi-v7/mdi-v7.css'

const app = createApp(App)

app.use(router)
app.use(Quasar, {
  plugins: {}, // import Quasar plugins and add here
  iconSet: iconSet
})

app.mount('#app')
