export const paint3 = (domRef) => {
  if (!domRef.value) return
  const c = document.createElement('canvas')

  const ctx = c.getContext('2d')

  const rect = domRef.value.getBoundingClientRect()
  c.width = rect.width
  c.height = rect.height

  ctx!.fillStyle = 'black'
  ctx!.fillRect(0, 0, c.width, c.height)

  ctx.globalCompositeOperation = 'source-atop'
  const gradient2 = ctx.createLinearGradient(0, c.height, 0, 0)
  gradient2.addColorStop(0, 'rgba(218, 139, 46, 1)')
  gradient2.addColorStop(0.3, 'rgba(0, 0, 0, 1)')

  gradient2.addColorStop(1, 'rgba(0, 0, 0, 1)')

  ctx!.fillStyle = gradient2
  ctx!.fillRect(0, 0, c.width, c.height)

  domRef.value.style.backgroundImage = 'url(' + c.toDataURL() + ')'
}

export const paint1 = (domRef) => {
  // disabled for now
  if (!domRef.value) return
  const c = document.createElement('canvas')

  const ctx = c.getContext('2d')

  const rect = domRef.value.getBoundingClientRect()
  c.width = rect.width
  c.height = rect.height

  ctx!.fillStyle = 'black'
  ctx!.fillRect(0, 0, c.width, c.height)

  ctx.globalCompositeOperation = 'source-atop'
  const gradient2 = ctx.createRadialGradient(c.width, c.height * 1, 100, c.width, c.height + 200, c.width / 1.3)
  // gradient2.addColorStop(0, 'rgba(255, 255, 200, .8)')
  gradient2.addColorStop(0, 'rgba(218, 139, 46, 1)')
  gradient2.addColorStop(1, 'rgba(0, 0, 0, 1)')

  ctx!.fillStyle = gradient2
  ctx!.fillRect(0, 0, c.width, c.height)

  domRef.value.style.backgroundImage = 'url(' + c.toDataURL() + ')'
}
