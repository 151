<script setup lang="ts">
import Footer from '@/components/FooterComponent.vue'
import { onMounted, ref } from 'vue'
import { useWindowSize, watchDebounced } from '@vueuse/core'
import { paint1 } from '@/helpers/canvas.ts'

const tab = ref('about')

// canvas paint logic
const { width, height } = useWindowSize()
const firstGradientBox: any = ref(null)

onMounted(() => {
  paint1(firstGradientBox)
})

watchDebounced(
  [width, height],
  () => {
    paint1(firstGradientBox)
  },
  { debounce: 10, maxWait: 300 }
)
</script>

<template>
  <q-page class="-bg-gray-300">
    <div>
      <div ref="firstGradientBox" class="-flex -h-svh -flex-col -content-center -justify-center" style="padding-top: 80px">
        <div class="-mx-5 -self-center -py-10">
          <q-card flat class="-bg-transparent -text-white">
            <q-card-section class="-flex -flex-wrap -pt-6 md:-pt-0">
              <div class="-max-w-screen-lg -text-left -text-6xl -font-semibold md:-text-8xl lg:-text-8xl">
                Industry Leading <span class="dl-yellow-text">Sports Odds</span>
              </div>
            </q-card-section>
            <q-card-section class="-flex -flex-wrap">
              <div>
                <div class="-max-w-screen-md -text-lg">
                  24/7 trading coverage and risk management by our USA and European team.
                </div>
                <q-btn class="q-mt-lg -px-0" size="lg" unelevated flat outline
                  ><a href="mailto:HR@dltrading.io" target="_blank" class="-underline -decoration-1">Contact DL Trading</a
                  ><q-icon name="mdi-arrow-right" class="-ml-3 -border -border-white" size="sm" />
                </q-btn>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div>
        <div v-if="false" class="-basis-1/2 -justify-center -self-center -bg-black -backdrop-opacity-20">
          <div class="-flex-row-gap-0 -flex -justify-center -self-center -px-10 -py-20">
            <q-card flat class="-max-w-screen-sm -border -border-none -bg-transparent -text-center -text-white">
              <q-card-section horizontal>
                <q-card-section>
                  <div class="-mb-5 -text-2xl -font-semibold">Comprehensive Odds</div>
                  <div class="-text-lg">
                    We specialize in in-play odds for a wide range of markets, including micros, player props, same game parlays,
                    and more. With flexible modeling,
                    <span class="-underline">we can originate odds for new, never-seen-before markets</span>.
                  </div>
                </q-card-section>
              </q-card-section>
            </q-card>
          </div>
        </div>
        <div class="-bg-yellow-50">
          <div class="-flex-row-gap-0 - -flex -justify-center -divide-x -divide-black -px-10 -py-20 -text-black">
            <div class="-self-center -pr-5 -text-center -text-2xl -font-semibold">Featured Podcasts</div>

            <div class="-self-center -pl-5 -text-left -text-lg">
              <a href="https://www.youtube.com/watch?v=O0shFH92cOc" target="_blank"
                ><div class="-underline">SharpCast</div>
                <div class="-text-xs">DL Trading</div></a
              >

              <div class="-mt-2">
                <a
                  href="https://podcasts.apple.com/us/podcast/getting-machiavellian-with-brandon-moran/id1654826176?i=1000629171609"
                  target="_blank"
                >
                  <div class="-underline">Unabated</div>
                  <div class="-text-xs">Brandon Moran</div></a
                >
              </div>
              <div class="-mt-2">
                <a
                  href="https://podcasts.apple.com/us/podcast/episode-52-are-sporttrades-market-makers-the-best-in/id1704895140?i=1000675330143"
                  target="_blank"
                  class="-whitespace-nowrap"
                >
                  <div class="-underline">Zero Latency</div>
                  <div class="-text-xs">Sporttrade</div></a
                >
              </div>
              <div class="-mt-2">
                <a
                  href="https://podcasts.apple.com/us/podcast/episode-54-is-most-sharp-action-just-arbing/id1704895140?i=1000676846809"
                  target="_blank"
                  class="-whitespace-nowrap"
                  ><div class="-underline">Zero Latency</div>
                  <div class="-text-xs">David Frohardt-Lane</div></a
                >
              </div>
              <div class="-mt-2">
                <a
                  href="https://podcasts.apple.com/cd/podcast/jeff-and-rufus-talk-to-david-frohardt-lane-brought-to/id1291010585?i=1000627896047"
                  target="_blank"
                  class="-whitespace-nowrap"
                  ><div class="-underline">Bet the Process</div>
                  <div class="-text-xs">David Frohardt-Lane</div></a
                >
              </div>
            </div>
          </div>
          <div>
            <div class="-ml-auto -mr-auto -flex -flex-col -divide-y -divide-black -px-10 -pb-10">
              <div class="-flex -max-w-screen-sm -justify-center -self-center">
                <q-tabs indicator-color="black" narrow-indicator dense v-model="tab" class="-text-lg -text-black">
                  <q-tab :ripple="false" name="about">About</q-tab>
                  <q-tab :ripple="false" name="odds">Odds</q-tab>
                  <q-tab :ripple="false" name="team">Team</q-tab>
                </q-tabs>
              </div>
              <div class="-max-w-screen-sm -self-center">
                <q-tab-panels
                  class="-ml-auto -mr-auto -mt-5 -min-h-[350px] -bg-yellow-50 -text-center sm:-min-h-[300px] md:-min-h-[250px]"
                  v-model="tab"
                  animated
                  transition-prev="jump-up"
                  transition-next="jump-up"
                >
                  <q-tab-panel name="about">
                    <div class="-mb-5 -text-2xl -font-semibold">About DL Trading</div>
                    <p class="-text-lg">
                      DL is focused on building algorithms to calculate profit-maximizing odds across a wide variety of sports and
                      customer bases
                    </p>
                  </q-tab-panel>
                  <q-tab-panel name="odds" class="-bg-yellow-50">
                    <div class="-mb-5 -text-2xl -font-semibold">Custom Risk Profile</div>
                    <p class="-text-lg">
                      We don't provide generic odds, every customer's odds are unique. DL Trading's odds are tailored to your
                      customers, platform, and goals.
                    </p>
                  </q-tab-panel>

                  <q-tab-panel name="team" class="-bg-yellow-50">
                    <div class="-mb-5 -text-2xl -font-semibold">Traders, Quants, and Developers</div>
                    <p class="-text-lg">
                      Many have deep sports and financial trading backgrounds. Sharp operators in multiple timezones are always
                      monitoring risk and event conditions.
                    </p>
                  </q-tab-panel>
                </q-tab-panels>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="-flex -flex-col -flex-wrap -gap-0 -bg-white">
      <div class="-basis-2/5 -bg-black -px-10 -py-20">
        <div class="-text-center -text-2xl -font-semibold -text-white">Better Odds With DL Trading</div>
        <div class="-text-center -text-white">
          <q-btn size="lg" unelevated flat outline
            ><a href="mailto:HR@dltrading.io" target="_blank" class="-underline -decoration-1">Contact DL Trading</a
            ><q-icon name="mdi-arrow-right" class="-ml-3 -border -border-white" size="sm" />
          </q-btn>
        </div>
      </div>
    </div>

    <Footer />
  </q-page>
</template>

<style lang="scss" scoped>
.bg-image {
  background-image: url('../assets/orange-out.png');
  background-repeat: no-repeat;
  background-size: 140vw 140vh; // 109vw 109vh
  background-color: black;
}

.bg-image-2 {
  background-image: url('../assets/orange-out.png');
  background-size: cover;
  background-size: 109vw 80vh;
  background-color: black;
}
</style>
