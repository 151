<script setup>
import { onUnmounted, onMounted } from 'vue'

// handle page title for 404
const originalTitle = document.title

onMounted(() => {
  document.title = 'Error 404 (Not Found)'
})

onUnmounted(() => {
  document.title = originalTitle
})
</script>

<template>
  <div class="-h-screen -bg-yellow-50">
    <div class="-ml-auto -mr-auto -w-fit -pt-32">
      <q-card flat class="-bg-transparent -text-black md:-mx-5">
        <q-card-section class="-pt-7 md:-pt-10">
          <div class="-text-3xl">DL Trading</div>
          <h1 class="-mt-5 -text-6xl -font-bold lg:-text-7xl">404 NOT FOUND</h1>
          <div class="-mt-5 -underline">
            <router-link to="/"> {{ '<' }} Back To Homepage</router-link>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>
